<template>
  <v-container fluid>
    <v-row class="page-wrapper">
      <v-col cols="12" md="6" lg="7" class="banner-wrapper p-0">
        <video-background
          :src="require('../../../../../public/media/video/login-bg-3.mp4')"
          style="height: 100vh"
          :poster="require('../../../../../public/img/login-bg-img.png')"
        >
          <div
            class="d-flex flex-column justify-content-between"
            style="height: 75%"
          >
            <img
              class="img-fluid rounded-lg mt-7 ml-10 iq-purple-logo"
              src="/img/iqplatform.png"
              alt="logo"
            />
            <div class="text-center">
              <img
                src="/img/powering-ecommerce.png"
                alt="powering-ecommerce"
                class="p-2 w-300px w-sm-400px w-md-450px"
              />
            </div>
          </div>
        </video-background>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="5"
        class="overflow-auto d-flex flex-column justify-content-between poppins container-wrapper-right h-100"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-grow-1 mt-lg-0 signin-container-wrapper"
        >
          <div class="signin-container w-325px w-lg-400px w-md-50 px-3 px-sm-0">
            <div class="font-weight-boldest mb-12">
              <h1 class="font-weight-boldest title mb-3 mt-15 mt-lg-0">
                Log in
              </h1>
              <p class="subtitle">Run, Manage & Scale your eCommerce</p>
            </div>
            <b-form @submit="signinUser">
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 ml-3">
                      <span class="svg-icon">
                        <v-icon size="28">mdi-account-outline</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    placeholder="Username"
                    required
                    v-model="form.email"
                    name="email"
                    type="email"
                    id="email"
                    class="email-input"
                    ref="email"
                    :state="isEmailValid"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="mt-10">
                <b-input-group class="input-group">
                  <b-input-group-append>
                    <div class="mt-5 mt-lg-6 ml-3">
                      <span class="svg-icon">
                        <v-icon size="24">mdi-lock</v-icon>
                      </span>
                    </div>
                  </b-input-group-append>
                  <b-form-input
                    required
                    v-model="form.password"
                    :type="passwordHidden ? 'password' : 'text'"
                    name="password"
                    id="password"
                    class="password-input"
                    ref="password"
                    placeholder="Password"
                  ></b-form-input>
                  <b-input-group-prepend
                    @click="passwordHidden = !passwordHidden"
                    class="password-prepend"
                  >
                    <div class="mt-5 mt-lg-mt-6 mr-5">
                      <span class="svg-icon">
                        <v-icon size="20"
                          >mdi-{{
                            passwordHidden ? "eye-off-outline" : "eye-outline"
                          }}</v-icon
                        >
                      </span>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
                <div
                  class="w-350px d-flex justify-content-between align-items-center mt-6"
                >
                  <span class="float-left text-white font-size-lg">
                    <router-link class="link-white" to="/forgot_password"
                      >Forgot Password?</router-link
                    >
                  </span>

                  <span class="float-right text-white font-size-lg"
                    ><router-link class="link-white" to="/onboarding/register"
                      >Sign up</router-link
                    ></span
                  >
                </div>
              </div>
              <div
                class="d-flex submit-button-container justify-content-center mb-14 mt-14 p-1"
              >
                <button
                  type="submit"
                  ref="submit"
                  class="submit-button m-1 font-weight-bolder"
                  :class="[
                    !isSubmitButtonClickable ? 'disabled' : '',
                    loading ? ' loading' : '',
                    isSubmitButtonClickable && !loading ? '' : '',
                  ]"
                >
                  <template v-if="loading">
                    LOGGING IN...
                    <v-progress-circular
                      :size="20"
                      width="3"
                      color="primary"
                      indeterminate
                      class="ml-2 mb-1"
                    ></v-progress-circular>
                  </template>
                  <template v-else>LOGIN</template>
                </button>
              </div>
            </b-form>
          </div>
        </div>
        <div
          class="d-flex justify-content-center text-white mb-3 mt-14 ml-3 ml-md-0"
        >
          <div>
            <img
              src="/img/iqfulfillment-white-foreground copy.png"
              class="w-140px mb-2 mr-2"
            />
            <span class="mx-1 font-size-lg mr-2">Powered by: </span>
            <img
              src="/img/IQ-Hybrid-white.png"
              alt="logo"
              class="img-fluid w-80px mr-2 mb-2"
            />
            <span class="font-size-h6"
              >© {{ currentYear }} - All rights reserved.</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";

import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Login",
  components: {
    VideoBackground,
  },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    loading: false,
    passwordHidden: true,
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    isSubmitButtonClickable: function() {
      return this.isEmailValid && this.isPasswordValid;
    },

    isEmailValid: function() {
      const reg = RegExp(
        // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      );
      return reg.test(this.form.email);
    },

    isPasswordValid: function() {
      return this.form.password.length !== 0;
    },
  },
  methods: {
    signinUser(event) {
      event.preventDefault();
      if (this.isSubmitButtonClickable && !this.loading) {
        let email = this.form.email;
        let password = this.form.password;

        // clear existing errors
        this.$store.dispatch(LOGOUT);

        // set spinner to submit button
        this.loading = true;

        // send login request
        this.$store.dispatch(LOGIN, { email, password });
        // go to which page after successfully login

        setTimeout(() => {
          this.loading = false;
        }, 2000);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>
